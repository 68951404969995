import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FormSection from '../../form-section/form-section.component';
import FileDragger from '../../file-dragger/file-dragger.component';
import { NSRecord } from '../../../../@types/record.types';
import { Form, FormInstance, Radio } from 'antd';

interface IProps {
  form: FormInstance<any>;
}

const UploadFileSection = ({ form }: IProps) => {
  const fileTypeOptions = Object.keys(NSRecord.FileTypeInfo).map((fileType) => {
    return { value: fileType, label: NSRecord.FileTypeInfo[fileType as keyof typeof NSRecord.FileTypeInfo].text };
  });

  return (
    <FormSection text="تحميل المحتوى" icon={faCloudArrowUp}>
      <Form.Item
        name="file_type"
        label="نوع الملف"
        rules={[{ required: true, message: 'أدخل نوع الملف' }]}
      >
        <Radio.Group options={fileTypeOptions} optionType="button" />
      </Form.Item>

      <FileDragger
        form={form}
        name="content_file"
        label="تحميل ملف المحتوى"
        rules={[{ required: true, message: 'قم بتحميل ملف واحد' }]}
      />
    </FormSection>
  )
}

export default UploadFileSection;
