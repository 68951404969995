import './login-form.less'
import {
  Button, Form, Input,
} from 'antd';
import { useLogin } from '../../../pages/login/login.hook';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

const LoginForm = () => {
  const { states, formHandlers } = useLogin();

  const { isLoading } = states;
  const { submitHandler } = formHandlers;

  return <Form
    name="login-form"
    className="login-form"
    onFinish={submitHandler}
    layout="vertical"
    requiredMark={false}
    disabled={isLoading.value}
    initialValues={{ remember: true }}
  >
    <div className="header">
      <h1>مرحباً بعودتك!</h1>
      <h2>قم بادخال بيانات تسجيل الدخول الخاصة بك</h2>
    </div>
    <div className="content">
      <Form.Item
        name="email"
        label="البريد الإلكتروني"
        rules={[{ required: true }, { type: 'email' }]}
      >
        <Input
          prefix={<UserOutlined />}
          placeholder="user@example.com"
          size="large"
          autoFocus
        />
      </Form.Item>

      <Form.Item
        name="password"
        label="كلمة المرور"
        rules={[{ required: true }]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="********"
          size="large"
          type="password"
        />
      </Form.Item>

      <Form.Item className="auth-options">
        <Button type="text">نسيت كلمة المرور</Button>
      </Form.Item>

      <Button loading={isLoading.value} htmlType="submit" className="submit-button">تسجيل الدخول</Button>
    </div>
  </Form>
}

export default LoginForm;
