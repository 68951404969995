import { NSDynamicField } from '../@types/dynamic_fields.types';
import { throwHttpError } from './errors';
import localStorageService from './local-storage.service';

class DynamicFieldService {
  API: string;

  constructor() {
    this.API = process.env.REACT_APP_API_ENDPOINT || '//acm-api-dev.sadasol.com';
  }

  private getOptions(method = 'GET', body?: any): RequestInit {
    const accessToken = localStorageService.getItem('access_token');
    const headers = {
      accept: 'application/json, text/plain, */*',
      'accept-language': 'en,ar;q=0.9,en-GB;q=0.8,en-US;q=0.7',
      'content-type': 'application/json',
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }) as any,
    }

    const options: RequestInit = { method, headers };

    if (body) options.body = JSON.stringify(body);

    return options;
  }

  async getDynamicFieldsData(): Promise<NSDynamicField.Dto[]> {
    return fetch(`${this.API}/items/dynamic_fields/`, this.getOptions())
      .then(throwHttpError)
      .then((response) => response.json())
      .then((response) => response?.data)
      .catch((error) => console.error(error));
  }
}

export default new DynamicFieldService();
