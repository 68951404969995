import './file-dragger.less'
import {
  Button, Form, Input, Row, Upload,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { FormInstance, FormItemProps } from 'antd/es/form';
import useFileDragger from './file-dragger.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

const { Dragger } = Upload;

export type FileDraggerProps = FormItemProps & {
  form: FormInstance<any>
}

const FileDragger = ({ form, ...formProps }: FileDraggerProps) => {
  const { states, functions, properties } = useFileDragger({ form, ...formProps });

  const { fileURL } = states;
  const { urlUploadRequest, inputHandler } = functions;
  const { draggerProps } = properties

  return (
    <Form.Item {...formProps}>
      <Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p>اضغط او اسحب الملف لهذه المنطقة</p>
        <div className="border" />

        <Form.Item className="action-input">
          <Row align="middle">
            <Input
              onChange={(e) => fileURL.set(e.target.value)}
              placeholder="أدخل رابط الملف"
              onClick={inputHandler}
              size="large"
            />
            <Button onClick={(e) => { e.stopPropagation(); urlUploadRequest() }} aria-label="رفع الملف">
              <FontAwesomeIcon icon={faUpload} color="white" />
            </Button>
          </Row>
        </Form.Item>
      </Dragger>
    </Form.Item>
  )
}

export default FileDragger;
