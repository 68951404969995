import './create-content.less'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Form, Spin } from 'antd';
import useCreateContent from './create-content.hook';

import UploadFileSection from '../../components/create-content/sections/upload-file/upload-file.section';
import OtherPropsSection from '../../components/create-content/sections/other-props/other-props.section';
import FooterSection from '../../components/create-content/sections/footer/footer.section';
import UploadTranscriptSection from '../../components/create-content/sections/upload-transcript/upload-transcript.section';

const CreateContentPage = () => {
  const {
    states, params, formHandlers, form,
} = useCreateContent();

  const { isLoading } = states;
  const { submitHandler, formValuesChangeHandler } = formHandlers;

  return (
    <div className="app-page create-content-page">
      <Spin spinning={isLoading.value}>
        <Form
          form={form}
          name="create-content"
          className="content"
          onFinish={submitHandler}
          layout="vertical"
          disabled={isLoading.value}
          requiredMark={false}
          onValuesChange={formValuesChangeHandler}
        >

          <UploadFileSection form={form} />
          <UploadTranscriptSection form={form} />
          <OtherPropsSection form={form} formHandlers={formHandlers} />
          <FooterSection states={states} params={params} />
        </Form>
      </Spin>
    </div>
  )
}

export default CreateContentPage;
