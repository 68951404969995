import { ReactNode } from 'react';

import { ConfigProvider } from 'antd';
import arEG from 'antd/es/locale/ar_EG';
import UserProvider from '../../../contexts/user.context';
import SafeNavigationProvider from '../../../contexts/safe-navigation/safe-navigation.context';

export const PageProviders = ({ children }: { children: ReactNode }) => {
  return (
    <SafeNavigationProvider>
      <UserProvider>
        <ConfigProvider
          direction="rtl"
          locale={arEG}
        >
          {children}
        </ConfigProvider>
      </UserProvider>
    </SafeNavigationProvider>
  );
};

export default PageProviders;
