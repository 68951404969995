import { faGear } from '@fortawesome/free-solid-svg-icons';
import {
  Form, FormInstance, Select, TreeSelect,
} from 'antd';
import FormSection from '../../form-section/form-section.component';
import DynamicFields from '../../dynamic-fields/dynamic-fields.component';
import useOtherProps from './other-props.hook';
import { tagsList } from '../../../../data/tags.data';
import { NSCreateContent } from '../../../../@types/create-content.types';

interface IProps {
  form: FormInstance<any>;
  formHandlers: NSCreateContent.FormHandlers;
}

const OtherPropsSection = ({ form, formHandlers }: IProps) => {
  const { states: otherPropsStates } = useOtherProps();

  const { categoryTreeData } = otherPropsStates;

  return <FormSection text="اعدادات أخرى" icon={faGear}>
    <Form.Item
      name="tags"
      label="الكلمات المفتاحية"
    >
      <Select
        allowClear
        mode="tags"
        placeholder="مقطع فيديو, وثائقي, ترفيهي"
        tokenSeparators={[',']}
        options={tagsList}
      />
    </Form.Item>

    <DynamicFields form={form} formHandlers={formHandlers} />

    <Form.Item
      name="category_id"
      label="التصنيف"
      rules={[{ required: true, message: 'اختر التصنيف المناسب للمحتوى' }]}
    >
      <TreeSelect
        treeDefaultExpandAll
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={categoryTreeData.value}
        placeholder="وثائقي"
      />
    </Form.Item>
  </FormSection>
}

export default OtherPropsSection;
