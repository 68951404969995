import './dynamic-fields.less'
import {
  Button, DatePicker, Form, FormInstance, FormListFieldData, FormListOperation, Input, Select, TimePicker,
} from 'antd';
import classNames from 'classnames';
import useDynamicFields from './dynamic-fields.hook';
import { NSDynamicField } from '../../../@types/dynamic_fields.types';
import { IStateValue } from '../../../@types/general.types';
import { CloseCircleFilled } from '@ant-design/icons';
import { useRef, useState } from 'react';
import RichTextModalForm from '../rich-text-modal/rich-text-modal.component';
import { NSCreateContent } from '../../../@types/create-content.types';

interface DynamicFieldsProps {
  form: FormInstance<any>;
  formHandlers: NSCreateContent.FormHandlers;
}

type FieldRowProps = DynamicFieldsProps & {
  fields: FormListFieldData[];
  actions: FormListOperation;
  dynamicFieldsList: IStateValue<NSDynamicField.Dto[]>;
  dynamicFields: { key: number, value: any }[];
  functions: NSDynamicField.Functions;
}

type FieldRowItemProps = DynamicFieldsProps & {
  field: FormListFieldData;
  actions: FormListOperation;
  dynamicFieldsList: IStateValue<NSDynamicField.Dto[]>;
  dynamicFields: { key: number, value: any }[];
  functions: NSDynamicField.Functions;
}

interface FieldKeyProps {
  field: FormListFieldData;
  dynamicFieldsList: IStateValue<NSDynamicField.Dto[]>;
  dynamicFields: { key: number; value: any }[];
  focusRef: React.RefObject<HTMLDivElement>;
  functions: NSDynamicField.Functions;
}

type FieldValueProps = DynamicFieldsProps & {
  field: FormListFieldData;
  dynamicFieldsList: IStateValue<NSDynamicField.Dto[]>;
  dynamicFields: { key: number, value: any }[];
}

const DynamicFields = ({ form, formHandlers }: DynamicFieldsProps) => {
  const { states, functions } = useDynamicFields();
  const dynamicFields = Form.useWatch(['dynamic_fields'], form) as { key: number; value: any }[];

  const { dynamicFieldsList } = states;

  return (
    <Form.Item label="الحقول الديناميكية">
      <Form.List name="dynamic_fields">
        {(fields, actions) => (
          <>
            <FieldRow
              actions={actions}
              fields={fields}
              dynamicFieldsList={dynamicFieldsList}
              dynamicFields={dynamicFields}
              functions={functions}
              form={form}
              formHandlers={formHandlers}
            />

            <Button
              className={classNames({ extend: !fields.length })}
              disabled={fields.length === dynamicFieldsList.value?.length}
              onClick={() => actions.add()}
            >
              إضافة عنصر
            </Button>
          </>
        )}
      </Form.List>
    </Form.Item>
  )
}

const FieldRow = (props: FieldRowProps) => {
  const { fields } = props;

  return (
    <div className={classNames('dynamic-fields-container', { empty: !fields.length })}>
      {fields.map((field) => <FieldRowItem {...props} field={field} />)}
    </div>
  )
}

const FieldRowItem = ({
  field, actions, dynamicFieldsList, dynamicFields, functions, form, formHandlers,
}: FieldRowItemProps) => {
  const focusRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={focusRef} key={field.key} className="dynamic-fields-row">
      <FieldKey
        field={field}
        dynamicFieldsList={dynamicFieldsList}
        dynamicFields={dynamicFields}
        focusRef={focusRef}
        functions={functions}
      />
      <FieldValue
        field={field}
        dynamicFieldsList={dynamicFieldsList}
        dynamicFields={dynamicFields}
        form={form}
        formHandlers={formHandlers}
      />
      <Button
        className="delete-btn"
        title="حذف الحقل"
        aria-label="حذف الحقل"
        icon={<CloseCircleFilled />}
        onClick={() => { actions.remove(field.name) }}
      />
    </div>
  )
}

const FieldKey = ({
  field, dynamicFieldsList, dynamicFields, focusRef, functions,
}: FieldKeyProps) => {
  const currentIndex = field.name;
  const isKeySelected = (dynamicFieldItem: NSDynamicField.Dto) => (
    dynamicFields.find((item) => item?.key === dynamicFieldItem.dynamic_field_id)
  )
  const isCurrentKey = (dynamicFieldItem: NSDynamicField.Dto) => {
    const currentKey = dynamicFields[currentIndex]?.key
    return dynamicFieldItem.dynamic_field_id === currentKey;
  }
  const dropdownOptions = dynamicFieldsList.value
    ?.filter((dynamicFieldItem) => !isKeySelected(dynamicFieldItem) || isCurrentKey(dynamicFieldItem))
    ?.map((dynamicFieldItem) => (
      { value: dynamicFieldItem.dynamic_field_id, label: dynamicFieldItem.value }
    ))

  return (
    <Form.Item noStyle name={[field.name, 'key']}>
      <Select
        onChange={() => {
          setTimeout(() => {
            functions.selectChangeHandler(focusRef)
          }, 10)
        }}
        allowClear={false}
        placeholder="أدخل اسم الحقل"
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label!).toString().toLowerCase().includes(input.toLowerCase())}
        options={dropdownOptions}
      />
    </Form.Item>
  )
}

const FieldValue = (
  {
    field, dynamicFieldsList, dynamicFields, form, formHandlers,
  }: FieldValueProps,
) => {
  const currentIndex = field.name;
  const fieldType = dynamicFieldsList.value.find(
    (dynamicFieldItem) => dynamicFields[currentIndex]?.key === dynamicFieldItem.dynamic_field_id,
  )?.fieldType
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <Form.Item noStyle name={[field.name, 'value']}>
      {
        (fieldType === NSDynamicField.FieldType.PLAIN_TEXT || fieldType === NSDynamicField.FieldType.NUMBER
          ? <Input className="focusable" placeholder="أدخل قيمة الحقل" type={fieldType === NSDynamicField.FieldType.NUMBER ? 'number' : undefined} />
          : (
            (fieldType === NSDynamicField.FieldType.DATE || fieldType === NSDynamicField.FieldType.DATE_TIME
              ? (
                <DatePicker
                  className="focusable"
                  showTime={fieldType === NSDynamicField.FieldType.DATE_TIME ? { format: 'HH:mm' } : undefined}
                  format={['DD/MM/YYYY', 'D/M/YYYY', 'DD/M/YYYY', 'D/MM/YYYY'].map((item) => (
                    `${item}${fieldType === NSDynamicField.FieldType.DATE_TIME ? ' HH:mm' : ''}`
                  ))}
                  placeholder="التاريخ (DD/MM/YYYY)"
                  allowClear={false}
                  suffixIcon={null}
                />
              )
              : (
                fieldType === NSDynamicField.FieldType.TIME
                  ? (
                    <TimePicker
                      className="focusable"
                      format={['HH:mm']}
                      placeholder="الوقت (HH:MM:SS)"
                      allowClear={false}
                      suffixIcon={null}
                    />
                  )
                  : (fieldType === NSDynamicField.FieldType.RICH_TEXT
                    ? (
                      (
                        <>
                          <RichTextModalForm
                            form={form}
                            field={field}
                            isModalOpen={{ value: isModalOpen, set: setModalOpen }}
                            formHandlers={formHandlers}
                          />
                          <Button className="focusable" onClick={() => { setModalOpen(true) }}>
                            اضغط لتحرير النص
                          </Button>
                        </>
                      )
                    )
                    : (
                      <p className="empty-message">اختر نوع الحقل</p>
                    ))
              )
            )
          )
        )
      }
    </Form.Item>
  )
}

export default DynamicFields;
