import './rich-text-editor.less'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  Form, FormInstance, FormItemProps,
} from 'antd';

type IProps = FormItemProps & {
  placeholder: string;
  form?: FormInstance<any>;
  controller?: { value: string; set: (value: string) => void }
}

const RichTextEditor = ({
  form, name, label, placeholder, controller,
}: IProps) => {
  const richTextState = Form.useWatch(name, form);

  return (
    <div className="rich-text-editor-container" role="listitem">
      <Form.Item label={label} name={name}>
        <CKEditor
          editor={ClassicEditor as any}
          data={controller ? controller.value : richTextState}
          config={{
            placeholder,
            language: 'ar',
            toolbar: {
              items: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'blockQuote', 'undo', 'redo'],
            },
          }}
          onChange={(_, editor) => {
            const data = editor.data.get();
            if (controller) {
              controller.set(data);
            } else {
              form?.setFieldValue(name, data);
            }
          }}
        />
      </Form.Item>
    </div>
  )
}

export default RichTextEditor;
