import './records.less'
import RecordsTable from '../../components/records/records-table/records-table.component';
import useRecords from './records.hook';

const RecordsPage = () => {
  const { states, fetchers } = useRecords();

  return (
    <div className="app-page records-page">
      <RecordsTable states={states} fetchers={fetchers} />
    </div>
  )
}

export default RecordsPage;
