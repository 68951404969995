import { RcFile } from 'antd/es/upload';
import { NSDynamicField } from './dynamic_fields.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faHeadphones, faVideo } from '@fortawesome/free-solid-svg-icons';

export namespace NSRecord {

  export enum FileType {
    DOCUMENT = 'DOCUMENT',
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO'
  }

  export enum QueueStatus {
    TO_BE_REVIEWED = 'TO_BE_REVIEWED',
    PROCESSING = 'PROCESSING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED'
  }

  export enum RecordStatus {
    PUBLISHED = 'PUBLISHED',
    DRAFT = 'DRAFT',
    DELETED = 'DELETED'
  }

  export const FileTypeInfo = {
    [FileType.DOCUMENT]: {
      text: 'مستند',
      icon: <FontAwesomeIcon icon={faHeadphones} />,
    },
    [FileType.VIDEO]: {
      text: 'فيديو',
      icon: <FontAwesomeIcon icon={faVideo} />,
    },
    [FileType.AUDIO]: {
      text: 'صوت',
      icon: <FontAwesomeIcon icon={faFile} />,
    },
  }

  export const RecordStatusInfo = {
    [RecordStatus.PUBLISHED]: {
      text: 'تم النشر',
      status: 'success',
    },
    [RecordStatus.DRAFT]: {
      text: 'مسودة',
      status: 'default',
    },
    [RecordStatus.DELETED]: {
      text: 'محذوف',
      status: 'failed',
    },
  }

  export interface Dto {
    record_id: number,
    user_id: string,
    file_type: FileType,
    file_id: string,
    category_id: number,
    transcript_text: string,
    transcript_file_id: string,
    tags: string[],
    queues: number[],
    records_dynamic_fields_id: number[],
    status: RecordStatus,
    created_at: string,
    updated_at: string,
  }

  export interface Data {
    fileType: FileType,
    contentFileId: string,
    contentFileName: string,
    categoryId: number | string,
    transcriptText: string,
    transcriptFileId: string,
    transcriptFileName: string,
    tags: string[],
    queues: QueueStatus,
    dynamicFields: NSDynamicField.Data[],
    status: RecordStatus,
    fileList?: any;
    contentFile?: any;
    transcriptFile?: any;
  }

  export interface Form {
    category_id: string;
    content_file: { file: RcFile, fileList: RcFile[] };
    transcript_file: { file: RcFile, fileList: RcFile[] };
    file_type: FileType;
    transcript_text: string;
    dynamic_fields: { key: number, value: any }[]
    tags: string[];
  }

  export interface TableData {
    key: string,
    id: string;
    category: string;
    file_type: FileType;
    status: RecordStatus;
    tags: string[];
    created_at: string;
    updated_at: string;
    hide?: boolean;
  }
}
