import React, { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/es/table';
import { Badge, message, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import { TableRowSelection } from 'antd/es/table/interface';
import { formatDateString } from '../../../services';
import { NSRecord } from '../../../@types/record.types';
import recordService from '../../../services/record.service';
import { IRecordsTableProps } from './records-table.component';
import categoryService from '../../../services/category.service';
import { PresetStatusColorType } from 'antd/es/_util/colors';

const useRecordsTable = ({ states, fetchers }: IRecordsTableProps) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<NSRecord.TableData[]>();
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  const { records } = states;
  const { fetchRecords } = fetchers;

  useEffect(() => {
    const tableCells = document.querySelectorAll('.ant-table-content > table');
    tableCells.forEach((cell) => {
      cell.setAttribute('tabIndex', '0');
    });
  }, []);

  useEffect(() => {
    getTableData()
      .then((res) => {
        setTableData(res as any);
      })
  }, [states.records])

  const createNewRecordHandler = () => (
    navigate('/create-content')
  )

  const deleteRecordsHandler = async () => {
    recordService.deleteRecords(selectedItems)
      .then(() => {
        message.success('تم حذف العناصر بنجاح')
        setSelectedItems([]);
        setTimeout(() => {
          fetchRecords();
        }, 200);
        setSelectedKeys([]);
      })
      .catch(() => {
        message.error('حدث خطأ اثناء حذف العناصر')
      })
  }

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: NSRecord.TableData[]) => {
      setSelectedKeys(selectedRowKeys);
      return setSelectedItems(selectedRows.map((selectedItem: NSRecord.TableData) => (selectedItem as any).id))
    },
    getCheckboxProps: (record: NSRecord.TableData) => ({
      id: record.id,
    }),
  };

  const tagsFilter = records.value?.reduce((acc, record) => {
    const tags = record.tags?.map((tag) => ({ text: tag.toUpperCase(), value: tag }));

    if (tags) {
      tags.forEach((tag) => {
        if (!acc.find((accTag) => accTag.text === tag.text && accTag.value === tag.value)) {
          acc.push(tag);
        }
      });
    }

    return acc;
  }, [] as Array<{ text: string, value: string }>);

  const tableColumns: ColumnsType<NSRecord.TableData> = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'رقم المعرف',
      render: (text) => text,
    },
    {
      key: 'category',
      dataIndex: 'category',
      title: 'التصنيف',
      filters: records.value ? Array.from(new Set(records.value.map((record) => record.category_id))).map((category) => ({ text: category, value: category })) : [],
      onFilter: (value: any, record: any) => record.category === value,
    },
    {
      key: 'file_type',
      dataIndex: 'file_type',
      title: 'نوع الملف',
      render: (file_type) => {
        const fileTypeInfo = NSRecord.FileTypeInfo[file_type as NSRecord.FileType];
        return (<>
          {fileTypeInfo.icon}
          &nbsp;
          {fileTypeInfo.text}
        </>)
      },
      filters: Object.keys(NSRecord.FileTypeInfo).map((fileType) => {
        const fileTypeInfo = NSRecord.FileTypeInfo[fileType as NSRecord.FileType];

        return { text: fileTypeInfo.text, value: fileType }
      }),
      onFilter: (value: any, record: any) => record.file_type === value,
    },
    {
      key: 'tags',
      dataIndex: 'tags',
      title: 'الكلمات المفتاحية',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            const color = tag.length > 5 ? 'geekblue' : 'green';
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
      filters: tagsFilter as any,
      onFilter: (value: any, record: any) => record.tags.includes(value),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'الحالة',
      render: (status) => {
        const statusInfo = NSRecord.RecordStatusInfo[status as NSRecord.RecordStatus];
        return (<Badge
          status={statusInfo.status as PresetStatusColorType}
          text={statusInfo.text}
        />)
      },
      filters: [
        {
          text: 'قيد المراجعة',
          value: 'TO_BE_REVIEWED',
        },
        {
          text: 'مسودة',
          value: 'DRAFT',
        },
        {
          text: 'فشل العملية',
          value: 'FAIL',
        },
        {
          text: 'تم النشر',
          value: 'PUBLISHED',
        },
      ],
      onFilter: (value: any, record: any) => record.status === value,
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: 'تاريخ الإنشاء',
    },
    {
      key: 'updated_at',
      dataIndex: 'updated_at',
      title: 'تاريخ التحديث',
    },
  ];

  const getTableData = async () => {
    const categoriesDto = await categoryService.getCategoriesData();

    return records.value ? records.value.map((record, i) => {
      return {
        key: `${i}`,
        id: `${record.record_id}`,
        category: `${categoriesDto.find((category) => record.category_id === parseInt(category.category_id))?.title}`,
        file_type: record.file_type,
        status: record.status,
        tags: record.tags ? record.tags : [],
        created_at: formatDateString(record.created_at),
        updated_at: formatDateString(record.updated_at),
      }
    }) : []
  }

  return {
    selectedKeys: { value: selectedKeys, set: setSelectedKeys },
    selectedItems: { value: selectedItems, set: setSelectedItems },
    search: { value: search, set: setSearch },
    tagsFilter,
    rowSelection,
    tableColumns,
    tableData,
    recordHandlers: {
      createNewRecordHandler,
      deleteRecordsHandler,
    },
  }
}

export default useRecordsTable;
