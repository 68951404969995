import { NSCategory } from '../@types/category.types';
import { ITree } from '../@types/general.types';
import { throwHttpError } from './errors';
import localStorageService from './local-storage.service';

class CategoryService {
  API: string;

  constructor() {
    this.API = process.env.REACT_APP_API_ENDPOINT || '//acm-api-dev.sadasol.com';
  }

  private getOptions(method = 'GET', body?: any): RequestInit {
    const accessToken = localStorageService.getItem('access_token');
    const headers = {
      accept: 'application/json, text/plain, */*',
      'accept-language': 'en,ar;q=0.9,en-GB;q=0.8,en-US;q=0.7',
      'content-type': 'application/json',
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }) as any,
    }

    const options: RequestInit = { method, headers };

    if (body) options.body = JSON.stringify(body);

    return options;
  }

  async getCategoriesData(): Promise<NSCategory.Dto[]> {
    return fetch(`${this.API}/items/categories`, this.getOptions())
      .then(throwHttpError)
      .then((response) => response.json())
      .then((response) => response?.data)
      .catch((error) => console.error(error));
  }

  async getCategoryData(categoryId: number): Promise<NSCategory.Dto> {
    return fetch(`${this.API}/items/categories/${categoryId}`, this.getOptions())
      .then(throwHttpError)
      .then((response) => response.json())
      .then((response) => response?.data)
      .catch((error) => console.error(error));
  }

  transformTreeData(data: any[]): ITree.Node[] {
    const idMapping: any = data.reduce((acc: any, el: any) => {
      acc[el.category_id] = { ...el, children: [] };
      return acc;
    }, {});

    const root: ITree.Node[] = [];

    data.forEach((node) => {
      const transformedNode = {
        value: node.category_id,
        title: node.title,
        children: idMapping[node.category_id].children,
      };

      if (node.parent_id === null) {
        root.push(transformedNode);
      } else {
        idMapping[node.parent_id].children.push(transformedNode);
      }
    });

    return root;
  }
}

export default new CategoryService();
