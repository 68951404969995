import { ReactNode } from 'react';
import './page-layout.less';

import { Content } from 'antd/es/layout/layout';
import Header from '../header/header.component';

export const PageLayout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Header />
      <Content>
        {children}
      </Content>
    </>
  );
};

export default PageLayout;
