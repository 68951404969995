import { IState } from './general.types';

export namespace NSDynamicField {
  export enum FieldType {
    PLAIN_TEXT = 'PLAIN_TEXT',
    RICH_TEXT = 'RICH_TEXT',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    TIME = 'TIME',
    DATE_TIME = 'DATE_TIME'
  }

  export interface Data {
    key: number,
    value: string
  }

  export interface Dto {
    dynamic_field_id: number;
    value: string;
    fieldType: FieldType;
  }

  export interface States {
    isLoading: IState<boolean>;
    isSaving: IState<boolean>;
  }

  export interface Params {
    id: string | undefined;
  }

  export interface Functions {
    selectChangeHandler: (focusRef: React.RefObject<HTMLDivElement>) => void;
  }
}
