import { useEffect } from 'react';
import axiosService from './services/axios.service';
import { HOME_PATH } from './config';
import './App.less';

import CreateContentPage from './pages/create-content/create-content.page';
import RecordsPage from './pages/records/records.page';
import PageGuard from './components/base/page-guard/page-guard.component';
import PageProviders from './components/base/page-providers/page-providers.component';
import LoginPage from './pages/login/login.page';
import PageLayout from './components/base/page-layout/page-layout.component';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';

function App() {
  useEffect(() => {
    axiosService.init();
  })

  return (
    <BrowserRouter>
      <PageProviders>
        <div className="App">
          <PageLayout>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route element={<PageGuard />}>
                <Route path="/create-content/:id?" element={<CreateContentPage />} />
                <Route path="/records" element={<RecordsPage />} />
                <Route path="/*" element={<Navigate to={HOME_PATH} replace />} />
              </Route>
            </Routes>
          </PageLayout>
        </div>
      </PageProviders>
    </BrowserRouter>
  );
}

export default App;
