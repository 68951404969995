import React, {
  useCallback, useContext, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { NSNavigation } from '../../@types/general.types';

export const SafeNavigationContext = React.createContext<NSNavigation.Context | undefined>(undefined);

export const useNavigation = () => {
  const context = useContext(SafeNavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};

export const SafeNavigationProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const navigateTo = useCallback((path: string) => {
    if (hasUnsavedChanges && !window.confirm('لديك تغييرات غير محفوظة، هل أنت متأكد أنك تريد المغادرة؟')) {
      return;
    }
    navigate(path);
  }, [hasUnsavedChanges, navigate]);

  const safeFunctionExecution = useCallback((fc: any) => {
    if (hasUnsavedChanges && !window.confirm('لديك تغييرات غير محفوظة، هل أنت متأكد أنك تريد المغادرة؟')) {
      return;
    }
    fc();
  }, [hasUnsavedChanges, navigate]);

  return (
    <SafeNavigationContext.Provider value={{ navigateTo, safeFunctionExecution, hasUnsavedChanges: { value: hasUnsavedChanges, set: setHasUnsavedChanges } }}>
      {children}
    </SafeNavigationContext.Provider>
  );
};

export default SafeNavigationProvider;
