import { NSCategory } from '../../../../@types/category.types';
import { ITree } from '../../../../@types/general.types';
import categoryService from '../../../../services/category.service';
import useDataFetcher from '../../../../hooks/data-fetcher.hook';

const useOtherProps = () => {
  const [categoryTreeData] = useDataFetcher<NSCategory.Dto[], ITree.Node[]>(
    () => categoryService.getCategoriesData(),
    categoryService.transformTreeData,
  )

  return {
    states: {
      categoryTreeData: { value: categoryTreeData },
    },
  }
}

export default useOtherProps;
