import { RcFile } from 'antd/lib/upload';
import { AxiosResponse } from 'axios';
import axiosService from './axios.service';
import { NSRecord } from '../@types/record.types';
import dynamicFieldRecordService from './dynamic-field-record.service';

const createNewFile = async ({ file, fileURL }: { file?: RcFile, fileURL?: string }): Promise<AxiosResponse<any> | null> => {
  try {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      return await axiosService.api.post('/files', formData);
    } else {
      return await axiosService.api.post('/files/import', { url: fileURL, data: {} });
    }
  } catch {
    return null;
  }
}

const createNewRecord = async (recordFormData: NSRecord.Form) => {
  const {
    file_type, content_file, transcript_file, transcript_text, dynamic_fields, tags, category_id,
  } = recordFormData;

  const contentFileId = content_file?.fileList?.[0]?.uid;
  const transcriptFileId = transcript_file?.fileList?.[0]?.uid;

  const payload: any = {
    file_type: file_type.toString(),
    file_id: contentFileId,
    transcript_text,
    transcript_file_id: transcriptFileId,
    category_id,
    status: NSRecord.RecordStatus.PUBLISHED,
    tags,
    queues: {
      create: [
        { status: NSRecord.QueueStatus.TO_BE_REVIEWED },
      ],
      update: [],
      delete: [],
    },
  }

  if (dynamic_fields?.length) {
    payload.records_dynamic_fields_id = {
      create: dynamic_fields.filter((df) => df.value).map((dynamicField) => {
        const { key, value } = dynamicField;
        const dfPayload: any = {
          records_record_id: '+',
          dynamic_fields_dynamic_field_id: { dynamic_field_id: key },
        }

        dfPayload.plain_text = value
        return dfPayload;
      }),
      update: [],
      delete: [],
    }
  }

  if (!transcript_file) {
    payload.transcript_text = transcript_text
  }

  return axiosService.api.post('/items/records', payload);
}

const updateExistingRecord = async (recordFormData: NSRecord.Form & { recordId: string, }) => {
  const {
    file_type, content_file, transcript_file, transcript_text, dynamic_fields, tags, category_id, recordId,
  } = recordFormData;

  const contentFileId = content_file?.fileList?.[0]?.uid;
  const transcriptFileId = transcript_file?.fileList?.[0]?.uid;

  const payload: any = {
    file_type: file_type.toString(),
    file_id: contentFileId,
    transcript_text,
    transcript_file_id: transcriptFileId,
    category_id,
    status: NSRecord.RecordStatus.PUBLISHED,
    tags,
    queues: {
      create: [
        { status: NSRecord.QueueStatus.TO_BE_REVIEWED },
      ],
      update: [],
      delete: [],
    },
  }

  if (dynamic_fields?.length) {
    const dynamicFieldsRecordDto = await dynamicFieldRecordService.getDynamicFieldsRecordDto();
    payload.records_dynamic_fields_id = {
      update: dynamic_fields
        .filter((dynamicField) => dynamicField.value)
        .map((dynamicField) => {
          const { key, value } = dynamicField;
          const dfPayload: any = {
            dynamic_fields_dynamic_field_id: { dynamic_field_id: key },
            plain_text: 'here testing value',
            id: dynamicFieldsRecordDto.find((dynamicFieldRecord) => (
              dynamicFieldRecord.dynamic_fields_dynamic_field_id === key
              && dynamicFieldRecord.records_record_id === parseInt(recordId)
            ))?.id,
          }
          dfPayload.plain_text = value
          return dfPayload;
        }),
      delete: [],
      create: [],
    }
  }

  if (!transcript_file) {
    payload.transcript_text = transcript_text
  }

  return axiosService.api.patch(`/items/records/${recordId}`, payload);
}

export default {
  createNewFile, createNewRecord, updateExistingRecord,
}
