import axios from 'axios';
import { API_ENDPOINT } from '../config';

const init = () => {
  api.defaults.headers.common.Accept = 'application/json, text/plain, */*'; // review
  api.defaults.headers.common['Accept-Language'] = 'en,ar;q=0.9,he;q=0.8';
}

const api = axios.create({
  baseURL: API_ENDPOINT,
});

const setAuthToken = (accessToken: string | undefined) => {
  api.defaults.headers.common.Authorization = accessToken ? `Bearer ${accessToken}` : undefined;
};

export default { init, api, setAuthToken } as const;
