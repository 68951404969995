import { Button } from 'antd';
import { NSDynamicField } from '../../../../@types/dynamic_fields.types';

interface IFooterSectionProps {
  states: NSDynamicField.States
  params: NSDynamicField.Params
}

const FooterSection = ({ states, params }: IFooterSectionProps) => {
  const { isSaving } = states;
  const { id } = params;

  return (
    <div className="footer">
      <Button
        type="primary"
        htmlType="submit"
        loading={isSaving.value}
      >
        {id ? 'حفظ التعديلات' : 'طلب النشر'}
      </Button>
    </div>
  )
}

export default FooterSection;
