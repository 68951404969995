import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../../../contexts/user.context';

const PageGuard = () => {
  const { functions } = useContext(UserContext);

  if (!functions.isAuthenticated) {
    return <Navigate to="/login" />
  }

  return <Outlet />
}

export default PageGuard;
