import { useContext, useEffect, useState } from 'react';
import { NSRecord } from '../../@types/record.types';
import recordService from '../../services/record.service';
import { UserContext } from '../../contexts/user.context';

const useRecords = () => {
  const userContext = useContext(UserContext);
  const [records, setRecords] = useState<NSRecord.Dto[]>([]);
  const [isLoading, setLoading] = useState(false);

  const fetchRecords = () => {
    setLoading(true);
    recordService.getRecordsDto()
      .then(async (res) => {
        const fetched = res.filter((value) => value.category_id !== null);
        setRecords(fetched);
      })
      .catch(() => {
        userContext.functions.expireSession();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchRecords();
  }, [])

  return {
    states: {
      records: { value: records, set: setRecords },
      isLoading: { value: isLoading, set: setLoading },
    },
    fetchers: { fetchRecords },
  }
}

export default useRecords;
