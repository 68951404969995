function formatDateString(dateString: string): string {
  const date = new Date(dateString);

  const year = date.getFullYear().toString().slice(2);
  const month = (`0${date.getMonth() + 1}`).slice(-2); // JavaScript months are 0-based, so we add 1
  const day = (`0${date.getDate()}`).slice(-2);
  const hour = (`0${date.getHours()}`).slice(-2);
  const minute = (`0${date.getMinutes()}`).slice(-2);

  const formattedDate = ` (${hour}:${minute}) ${year}/${month}/${day}`;
  return formattedDate;
}

export const generateFiveDigitNumber = () => {
  return Math.floor(10000 + Math.random() * 90000);
}

export { formatDateString };
