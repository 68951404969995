import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/user.context';
import { HOME_PATH } from '../../config';

import LoginForm from '../../components/login/login-form/login-form.component';

const LoginPage = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (userContext.functions.isAuthenticated) { navigate(HOME_PATH); }
  }, [userContext.accessToken.value]);

  return <div className="app-page login-page">
    <LoginForm />
  </div>
}

export default LoginPage;
