import { ReactNode, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import './header-button.less'
import { Button } from 'antd';
import { SafeNavigationContext } from '../../../../contexts/safe-navigation/safe-navigation.context';

interface IProps {
  children: ReactNode,
  to?: string,
  onClick?: (...args: any[]) => void;
}

const HeaderButton = ({ children, to, onClick }: IProps) => {
  const navigationContext = useContext(SafeNavigationContext);
  const location = useLocation();
  const { pathname } = location;
  return <Button
    type="text"
    onClick={() => {
      (to && navigationContext?.navigateTo(to)); (onClick && navigationContext?.safeFunctionExecution(onClick))
    }}
    className={`header-button${pathname === to ? ' selected' : ''}`}
  >
    {children}
  </Button>
}

export default HeaderButton
