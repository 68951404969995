import { NSUser } from '../@types/user.types';
import { throwHttpError } from './errors';
import localStorageService from './local-storage.service';

class UserService {
  API: string;

  constructor() {
    this.API = process.env.REACT_APP_API_ENDPOINT || '//acm-api-dev.sadasol.com';
  }

  private getOptions(method = 'GET', body?: any): RequestInit {
    const accessToken = localStorageService.getItem('access_token');
    const headers = {
      accept: 'application/json, text/plain, */*',
      'accept-language': 'en,ar;q=0.9,en-GB;q=0.8,en-US;q=0.7',
      'content-type': 'application/json',
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }) as any,
    }

    const options: RequestInit = { method, headers };

    if (body) options.body = JSON.stringify(body);

    return options;
  }

  async getUserData(): Promise<NSUser.Data> {
    return fetch(`${this.API}/users/me?fields[]=id&fields[]=language&fields[]=first_name&fields[]=last_name&fields[]=email&fields[]=last_page&fields[]=theme&fields[]=tfa_secret&fields[]=avatar.id&fields[]=role.admin_access&fields[]=role.app_access&fields[]=role.id&fields[]=role.enforce_tfa`, this.getOptions())
      .then(throwHttpError)
      .then((response) => response.json())
      .then((response) => response?.data)
      .catch((error) => console.error(error));
  }

  async authUser(email: string, password: string): Promise<string | null> {
    return fetch(
      '//acm-api-dev.sadasol.com/auth/login',
      this.getOptions('POST', { email, password, mode: 'cookie' }),
    )
      .then((response) => response.json())
      .then((res) => res.data.access_token)
      .catch(() => null)
  }
}

export default new UserService();
