import './header.less';
import { useContext } from 'react';
import HeaderButton from './header-button/header-button.component';
import { UserContext } from '../../../contexts/user.context';

const Header = () => {
  const { user, functions } = useContext(UserContext);

  return (
    <div className="header-container">
      {user.value && (
        <>
          <div className="left-nav">
            <HeaderButton to="/records">قائمة المحتوى</HeaderButton>
          </div>

          <div className="right-nav">
            <HeaderButton onClick={functions.logout}>تسجيل الخروج</HeaderButton>
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
