import './records-table.less'
import { Button, Input } from 'antd';
import useRecordsTable from './records-table';
import AntTable from '../table/table.component';
import { NSRecord } from '../../../@types/record.types';
import { NSRecords } from '../../../@types/records.types';

export interface IRecordsTableProps {
  states: NSRecords.States;
  fetchers: NSRecords.Fetchers,
}

const RecordsTable = ({ states, fetchers }: IRecordsTableProps) => {
  const { isLoading } = states;
  const {
    selectedItems, rowSelection, tableColumns, recordHandlers, tableData,
  } = useRecordsTable({ states, fetchers });

  const { createNewRecordHandler, deleteRecordsHandler } = recordHandlers;

  return (
    <div className="records-table-container">
      <div className="actions">
        <div className="nav">
          <Input placeholder="ابحث" name="search" />
          {!!selectedItems.value.length && (
            <Button
              className="delete"
              onClick={deleteRecordsHandler}
            >
              {`حذف العناصر المحددة (${selectedItems.value.length})`}
            </Button>

          )}
        </div>

        <div className="nav">
          <Button onClick={createNewRecordHandler}>
            انشاء عنصر جديد
          </Button>
        </div>
      </div>

      <AntTable<NSRecord.Dto>
        rowSelection={rowSelection}
        columns={tableColumns as any}
        data={tableData as any}
        isLoading={isLoading.value}
      />
    </div>
  );
}

export default RecordsTable;
