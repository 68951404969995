import './table.less'
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';

export interface AntTableProps<T> {
  rowSelection: any;
  columns: ColumnsType<T>;
  data: T[];
  isLoading: boolean
}

const AntTable = <T,>({
  isLoading, rowSelection, columns, data,
}: AntTableProps<T>) => {
  const navigate = useNavigate();

  return <Table
    rowSelection={rowSelection as any}
    columns={columns as any}
    dataSource={data as any}
    pagination={{ position: ['bottomCenter'] }}
    onRow={(record) => {
      return {
        onClick: () => { navigate(`/create-content/${record.id}`) },
      };
    }}
    loading={isLoading}
  />
}

export default AntTable;
