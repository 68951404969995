import { useContext, useState } from 'react'
import { message } from 'antd';
import { UserContext } from '../../contexts/user.context';
import userService from '../../services/user.service';

export const useLogin = () => {
  const [isLoading, setLoading] = useState(false);
  const { accessToken } = useContext(UserContext);

  const submitHandler = async (e: { email: string, password: string }) => {
    const { email, password } = e;

    setLoading(true);
    userService.authUser(email, password)
      .then((fetchedAccessToken) => {
        if (!fetchedAccessToken) {
          message.error('البريد الإلكتروني او كلمة المرور غير صحيحة');
          return;
        }

        accessToken.set(fetchedAccessToken);
        message.success('تم تسجيل الدخول بنجاح');
      })
      .catch(() => {
        message.success('حدث خطأ اثناء تسجيل الدخول');
      })
      .finally(() => {
        setLoading(false);
      })
  }

  return {
    states: {
      isLoading: { value: isLoading, set: setLoading },
    },
    formHandlers: {
      submitHandler,
    },
  }
}
