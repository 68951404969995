import { faFileText } from '@fortawesome/free-solid-svg-icons';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FormSection from '../../form-section/form-section.component';
import FileDragger from '../../file-dragger/file-dragger.component';
import RichTextEditor from '../../rich-text-editor/rich-text-editor.component';
import { Form, FormInstance } from 'antd';

interface IProps {
  form: FormInstance<any>
}

const UploadTranscriptSection = ({ form }: IProps) => {
  const transcriptText = Form.useWatch('transcript_text', form);

  return <FormSection text="تحميل النص" icon={faFileText}>
    <RichTextEditor
      form={form}
      name="transcript_text"
      placeholder="أدخل نص المحتوى .."
      label="نص المحتوى"
    />

    <FileDragger
      form={form}
      name="transcript_file"
      label="تحميل ملف النص"
      rules={[{
        required: !transcriptText,
        message: 'قم بتحميل ملف واحد',
      }]}
    />
  </FormSection>
}

export default UploadTranscriptSection;
