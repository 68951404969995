import {
  Form, FormInstance, FormListFieldData, Modal,
} from 'antd';
import RichTextEditor from '../rich-text-editor/rich-text-editor.component';
import { IState } from '../../../@types/general.types';
import { NSCreateContent } from '../../../@types/create-content.types';

interface IProps {
  form: FormInstance<any>;
  formHandlers: NSCreateContent.FormHandlers;
  field: FormListFieldData;
  isModalOpen: IState<boolean>;
}

const RichTextModalForm = ({
  form, formHandlers, field, isModalOpen,
}: IProps) => {
  return (
    <Modal
      title="أدخل التفاصيل"
      open={isModalOpen.value}
      onCancel={() => { isModalOpen.set(false) }}
      onOk={() => { isModalOpen.set(false) }}
      cancelButtonProps={{ className: 'hidden' }}
    >
      <Form onFinish={formHandlers.submitHandler} form={form}>
        <RichTextEditor name={['dynamic_fields', field.name, 'value']} placeholder="أدخل نص الحقل" form={form} />
      </Form>
    </Modal>
  );
};

export default RichTextModalForm;
