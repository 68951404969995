import {
  useCallback, useContext, useEffect, useLayoutEffect, useState,
} from 'react';
import { Form, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import directusService from '../../services/directus.service';
import { NSRecord } from '../../@types/record.types';
import { SafeNavigationContext } from '../../contexts/safe-navigation/safe-navigation.context';
import recordService from '../../services/record.service';
import { generateRcFileTemplate } from '../../utils/rcfile.util';
import moment from 'moment';

const useCreateContent = () => {
  const navigate = useNavigate();
  const navigationContext = useContext(SafeNavigationContext);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (id) {
        setLoading(true);
        try {
          const recordRes = await recordService.getRecordData(id);
          const dynamicFields = recordRes.dynamicFields.map((df) => ({
            ...df,
            value: moment(df.value).isValid() ? moment(df.value) : df.value,
          }))

          form.setFieldsValue({
            file_type: recordRes.fileType,
            category_id: recordRes.categoryId,
            dynamic_fields: dynamicFields,
            content_type: recordRes.fileType,
            transcript_text: recordRes.transcriptText,
            content_file: recordRes.contentFileName ? {
              fileList: [generateRcFileTemplate({
                id: recordRes.contentFileId, title: recordRes.contentFileName,
              })],
            } : undefined,
            tags: recordRes.tags,
            transcript_file: recordRes.transcriptFileName ? {
              fileList: [generateRcFileTemplate({
                id: recordRes.transcriptFileId, title: recordRes.transcriptFileName,
              })],
            } : undefined,
          });
        } catch (error) {
          console.error(error)
        } finally {
          setLoading(false);
        }
      }
    }

    init();
  }, [])

  useLayoutEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (form.isFieldsTouched()) {
        event.preventDefault();
        event.returnValue = 'لديك تغييرات غير محفوظة، هل أنت متأكد أنك تريد المغادرة؟';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [form]);

  useEffect(() => {
    if (form.isFieldsTouched()) {
      navigationContext?.hasUnsavedChanges.set(true);
    } else {
      navigationContext?.hasUnsavedChanges.set(false);
    }
  }, [form]);

  const submitHandler = async (formData: NSRecord.Form) => {
    let res;

    try {
      setSaving(true)
      if (id) {
        res = await directusService.updateExistingRecord({ ...formData, recordId: id });
      } else {
        res = await directusService.createNewRecord(formData);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false);
    }

    if (res?.status === 200) {
      setTimeout(() => {
        navigate('/records');
      }, 200);
      message.success(`تم ${id ? 'حفظ' : 'انشاء'} المحتوى بنجاح`);
    } else {
      message.error(`حدث خطأ أثناء ${id ? 'حفظ' : 'انشاء'} المحتوى`);
    }
  }

  const formValuesChangeHandler = useCallback(() => (
    navigationContext?.hasUnsavedChanges.set(form.isFieldsTouched())
  ), []);

  return {
    states: {
      isLoading: { value: isLoading, set: setLoading },
      isSaving: { value: isSaving, set: setSaving },
    },
    formHandlers: {
      submitHandler,
      formValuesChangeHandler,
    },
    params: {
      id,
    },
    form,
  }
}

export default useCreateContent;
