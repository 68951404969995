import { RcFile } from 'antd/lib/upload';
import { NSFile } from '../@types/file';
import { DirectusResponse } from '../@types/general.types';
import { throwHttpError } from './errors';
import localStorageService from './local-storage.service';

class FileService {
  API: string;

  constructor() {
    this.API = process.env.REACT_APP_API_ENDPOINT || '//acm-api-dev.sadasol.com';
  }

  private getOptions(method = 'GET', body?: any): RequestInit {
    const accessToken = localStorageService.getItem('access_token');
    const isFormData = body instanceof FormData;

    const headers = {
      accept: 'application/json, text/plain, */*',
      'accept-language': 'en,ar;q=0.9,en-GB;q=0.8,en-US;q=0.7',
      'content-type': 'application/json',
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }) as any,
    }

    const options: RequestInit = { method, headers };

    if (body) {
      options.body = isFormData ? body : JSON.stringify(body);
    }

    return options;
  }

  async getFile(fileId?: string): Promise<NSFile.Dto> {
    return fetch(`${this.API}/files/${fileId}`, this.getOptions())
      .then(throwHttpError)
      .then((response) => response.json())
      .then((response) => response?.data)
      .catch((error) => console.error(error));
  }
}

export default new FileService();
