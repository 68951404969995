import './form-section.less'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  text: string,
  icon: IconDefinition,
  children: any,
}

const FormSection = ({ text, icon, children }: IProps) => {
  return (
    <section aria-label={text} className="form-section">
      <div className="header">
        <FontAwesomeIcon icon={icon} />
        <h1>{text}</h1>
      </div>
      {children}
    </section>
  )
}

export default FormSection;
