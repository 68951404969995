import React, { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { NSUser } from '../@types/user.types';
import { IPropsChildren } from '../@types/general.types';
import userService from '../services/user.service';
import localStorageService from '../services/local-storage.service';
import axiosService from '../services/axios.service';

export const UserContext = React.createContext<NSUser.Context>({
  accessToken: { value: undefined, set: () => { } },
  user: { value: undefined, set: () => { } },
  functions: { logout: () => { }, expireSession: () => { }, isAuthenticated: false },
});

const UserProvider: React.FC<IPropsChildren> = ({ children }) => {
  const [user, setUser] = useState<NSUser.Data>();
  const [accessToken, setAccessToken] = useState<string | undefined>(
    localStorageService.getItem('access_token') ?? undefined,
  );

  const isAuthenticated = !!accessToken;

  const getUserData = useCallback(() => {
    userService.getUserData()
      .then((res) => (
        setUser(res)
      ))
      .catch((err) => {
        message.error('حدث خطأ في استرجاع بيانات الدخول');
        setAccessToken(undefined);
      });
  }, [accessToken]);

  useEffect(() => {
    localStorageService.setItem('access_token', accessToken);
    axiosService.setAuthToken(accessToken);

    if (!accessToken) {
      setUser(undefined);
      return;
    }

    getUserData();
  }, [accessToken])

  const expireSession = () => {
    setAccessToken(undefined);
    message.destroy();
    message.info('لقد انتهت الجلسة')
  }

  const logout = () => {
    setAccessToken(undefined);
    message.info('لقد قمت بتسجيل الخروج')
  }

  return (
    <UserContext.Provider value={{
      accessToken: { value: accessToken, set: setAccessToken },
      user: { value: user, set: setUser },
      functions: { logout, expireSession, isAuthenticated },
    }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider;
