import { useState } from 'react';
import useDataFetcher from '../../../hooks/data-fetcher.hook';
import { NSDynamicField } from '../../../@types/dynamic_fields.types';
import dynamicFieldService from '../../../services/dynamic-field.service';

const useDynamicFields = () => {
  const [isModalShown, setModalShown] = useState<number | null>(null);
  const [dynamicFieldsList] = useDataFetcher<NSDynamicField.Dto[]>(() => dynamicFieldService.getDynamicFieldsData());

  const selectChangeHandler = (focusRef: React.RefObject<HTMLDivElement>) => {
    if (!focusRef || !focusRef.current) return;

    const targetElement = focusRef.current.querySelector('.focusable .ant-picker-input input') as HTMLElement
      || focusRef.current.querySelector('.focusable') as HTMLElement;

    targetElement?.focus();
  }

  return {
    states: {
      isModalShown: { value: isModalShown, set: setModalShown },
      dynamicFieldsList: { value: dynamicFieldsList },
    },
    functions: {
      selectChangeHandler,
    },
  }
}

export default useDynamicFields;
