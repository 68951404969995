import { useState, useEffect } from 'react';

const useDataFetcher = <T = any, U = T>(fetchFunction: () => Promise<T>, transformationFunction?: (data: T) => U) => {
  const [data, setData] = useState<T | U>();

  useEffect(() => {
    fetchFunction()
      .then((res) => (
        setData(
          transformationFunction
            ? transformationFunction(res)
            : res,
        )
      ))
      .catch();
  }, [])

  return [data as U, setData] as const;
}

export default useDataFetcher;
