import { UploadFile } from 'antd';
import { generateRandomNumber } from './general.utils';
import { UploadFileStatus } from 'antd/es/upload/interface';

export const generateRcFileTemplate = ({
  id, title, type, filesize, status = 'done',
}: { id?: string, title: string, type?: string, filesize?: number, status?: UploadFileStatus }): UploadFile<any> => {
  return (
    {
      uid: id ?? generateRandomNumber(10).toString(),
      name: title,
      lastModified: 1694349240145,
      size: filesize,
      type,
      status,
    }
  )
}
