import { NSDynamicField } from '../@types/dynamic_fields.types';
import { DirectusResponse } from '../@types/general.types';
import { NSRecord } from '../@types/record.types';
import dynamicFieldRecordService from './dynamic-field-record.service';
import { throwHttpError } from './errors';
import fileService from './file.service';
import localStorageService from './local-storage.service';

class RecordService {
  API: string;

  constructor() {
    this.API = process.env.REACT_APP_API_ENDPOINT || '//acm-api-dev.sadasol.com';
  }

  private getOptions(method = 'GET', body?: any): RequestInit {
    const accessToken = localStorageService.getItem('access_token');
    const headers = {
      accept: 'application/json, text/plain, */*',
      'accept-language': 'en,ar;q=0.9,en-GB;q=0.8,en-US;q=0.7',
      'content-type': 'application/json',
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }) as any,
    }

    const options: RequestInit = { method, headers };

    if (body) options.body = JSON.stringify(body);

    return options;
  }

  async getRecordDto(recordId: string): Promise<NSRecord.Dto> {
    return fetch(`${this.API}/items/records/${recordId}`, this.getOptions())
      .then(throwHttpError)
      .then((response) => response.json())
      .then((response) => response?.data)
      .catch((error) => console.error(error));
  }

  async getRecordsDto(): Promise<NSRecord.Dto[]> {
    return fetch(`${this.API}/items/records`, this.getOptions())
      .then(throwHttpError)
      .then((response) => response.json())
      .then((response) => response?.data)
      .catch((error) => console.error(error));
  }

  async getRecordData(recordId: string): Promise<NSRecord.Data> {
    const recordDto = (await this.getRecordDto(recordId));

    return this.convertRecordDto(recordDto);
  }

  async deleteRecords(records: string[]): Promise<DirectusResponse<NSRecord.Dto[]>> {
    return fetch(`${this.API}/items/records/`, this.getOptions('DELETE', records))
      .then(throwHttpError)
      .then((response) => response.json())
      .then((response) => response?.data)
      .catch((error) => console.error(error));
  }

  async convertRecordDto(recordDto: NSRecord.Dto): Promise<NSRecord.Data> {
    const dynamicFieldsRecordDto = await dynamicFieldRecordService.getDynamicFieldsRecordDto();
    const contentFile = await fileService.getFile(recordDto.file_id);
    const transcriptFile = await fileService.getFile(recordDto.transcript_file_id);

    const dynamicFields: NSDynamicField.Data[] = recordDto.records_dynamic_fields_id.map((item) => {
      const dynamicField = dynamicFieldsRecordDto.find(
        (iitem) => iitem.id === item,
      )
      const value = (dynamicField?.plain_text || dynamicField?.date) ?? '';

      return { key: dynamicField?.dynamic_fields_dynamic_field_id ?? 0, value }
    })

    const recordData: NSRecord.Data = {
      fileType: recordDto.file_type,
      contentFileId: recordDto.file_id,
      contentFileName: contentFile.filename_download,
      categoryId: recordDto.category_id,
      transcriptText: recordDto.transcript_text,
      transcriptFileId: recordDto.transcript_file_id,
      transcriptFileName: transcriptFile.filename_download,
      tags: recordDto.tags,
      queues: NSRecord.QueueStatus.TO_BE_REVIEWED,
      dynamicFields,
      status: recordDto.status,
    }
    return recordData;
  }
}

export default new RecordService();
