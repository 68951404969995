import { useState } from 'react';
import { Form, message, UploadProps } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import directusService from '../../../services/directus.service';
import { FileDraggerProps } from './file-dragger.component';
import { generateRcFileTemplate } from '../../../utils/rcfile.util';

const useFileDragger = ({ form, ...formProps }: FileDraggerProps) => {
  const [fileURL, setFileURL] = useState('');
  const fileList = form.getFieldValue(formProps.name)?.fileList ?? []

  Form.useWatch(formProps.name, form);

  const fileUploadRequest = async (options: UploadRequestOption<any>) => {
    const file = options.file as RcFile;
    let fileList = [];
    const { onSuccess } = options;

    try {
      const res = await directusService.createNewFile({ file: file as RcFile })
      const fileData = res?.data.data;

      fileList = [generateRcFileTemplate(fileData)]
      onSuccess!('Done')
      message.success(`تم رفع الملف بنجاح (${file.name ?? 'الإسم غير معرف'})`);
    } catch {
      fileList = [generateRcFileTemplate({ title: fileURL, status: 'error' })]
      message.error(`حدث خطأ في رفع الملف (${file.name ?? 'الإسم غير معرف'})`);
    }

    form.setFieldValue(formProps.name, { fileList })
  };

  const urlUploadRequest = async () => {
    let fileList = [];

    try {
      const res = await directusService.createNewFile({ fileURL })
      const fileData = res?.data.data;

      fileList = [generateRcFileTemplate(fileData)]
      message.success(`تم رفع الملف بنجاح (${fileData.title})`);
      setFileURL('');
    } catch {
      fileList = [generateRcFileTemplate({ title: fileURL, status: 'error' })]
      message.error(`حدث خطأ في رفع الملف (${fileURL})`);
    }

    form.setFieldValue(formProps.name, { fileList })
  }

  // const fileURLUploadRequest = async (fileURL: string) => {
  //   form.setFieldsValue({});

  //   try {
  //     const res = await directusService.createNewFile({ fileURL })
  //     message.success(`تم رفع الملف بنجاح (${file.name ?? 'الإسم غير معرف'})`);
  //   } catch {
  //     message.error(`حدث خطأ في رفع الملف (${file.name ?? 'الإسم غير معرف'})`);
  //   }
  // };

  // const uploadRequest = async (options?: UploadRequestOption<any>, fileURL?: string) => {
  //   const file = options.file as RcFile;
  //   const { uid } = file

  //   props.fileList.set([{
  //     uid: file ? file.uid : 'uid',
  //     name: file ? file.name : (fileURL as string),
  //   }])

  //   try {
  //     const res = await directusService.createNewFile(options)
  //     props.controller.set((res?.data as any).id);

  //     props.fileList.set(() => ([{ status: 'done', name: '', uid: '' }]))

  //     props.fileList.set((prevFileList) => prevFileList.map((file) => (
  //       { ...file, status: 'done' }
  //     )))
  //     message.success(`تم رفع الملف بنجاح (${options.file ? options.file.name : (res?.data as any).title})`);
  //   } catch (error) {
  //     message.error(`حدث خطأ في رفع الملف (${options.file ? options.file.name : ''})`);
  //   }
  // };

  const draggerProps: UploadProps = {
    name: 'file',
    multiple: true,
    customRequest: fileUploadRequest,
    fileList,
    // fileList: form.getFieldValue(formProps.name) ?? [],
    // onRemove() {
    //   form.getFieldValue(props.name)
    // },
  };

  const inputHandler = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => (
    e.stopPropagation()
  );

  return {
    states: {
      fileURL: { value: fileURL, set: setFileURL },
    },
    functions: { inputHandler, urlUploadRequest },
    properties: { draggerProps },
  }
}

export default useFileDragger;
